













import Vue from "vue";
import { callableFunctions } from "@/firebase";

import TextInput from "@/components/TextInput.vue";
import ActionButton from "@/components/ActionButton.vue";

export default Vue.extend({
  name: "AccountAdd",
  components: {
    TextInput,
    ActionButton
  },
  data: () => ({
    description: "",
    name: "",
    phone: "",
    submitting: false,
    error: null as Error | null
  }),
  methods: {
    async createAccount() {
      if (this.submitting) {
        return;
      }
      this.submitting = true;
      this.error = null;
      try {
        if (!this.name) {
          throw new Error("You must provide a name for the new Reactor.");
        }
        const response = await callableFunctions.registerAccount({
          name: this.name.trim(),
          description: this.description.trim(),
          phone: this.phone.trim(),
          adminRun: true
        });
        const registerAccountData = response.data as { accountId: string };
        void this.$router.replace(`/app/accounts/${registerAccountData.accountId}`);
      } catch (error) {
        console.error(error);
        this.error = error as Error;
      }
      this.submitting = false;
    }
  }
});
